.card {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	background: #222;
	color: #fff;
	margin: 0 0 64px;
	flex-grow: 0;
	flex-shrink: 0;
	flex-basis: calc(99.9% * 1 - 0px);
	width: calc(99.9% * 1 - 0px);
	cursor: pointer;
}

.card:nth-child(1n) {
	margin-right: 30px;
	margin-left: 0;
}

.card:last-child {
	margin-right: 0;
}

.card:nth-child(undefinedn) {
	margin-right: 0;
	margin-left: auto;
}

.card.black {
	color: #000;
}

.card.half-size {
	height: 300px;
}

.card a {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	background-size: 100%;
	text-align: center;
	position: relative;
}

.card a::after {
	position: absolute;
	bottom: 0;
	left: 0;
	content: '';
	-webkit-box-shadow: inset 0px -200px 300px -4px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: inset 0px -200px 300px -4px rgba(0, 0, 0, 0.75);
	box-shadow: inset 0px -200px 300px -4px rgba(0, 0, 0, 0.75);
	width: 100%;
	height: 100%;
	opacity: 1;
	transition: opacity 0.375s ease;
	z-index: 1;
}

.card a::before {
	position: absolute;
	bottom: 0;
	left: 0;
	content: '';
	background-image: url(../../assets/images/pixel-corner.svg);
	width: 50px;
	height: 50px;
	background-size: cover;
	opacity: 1;
	transition: opacity 0.375s ease;
}

.card a:hover {
	border: 0 solid transparent;
}

.card a:hover::before,
.card a:hover::after {
	opacity: 0;
}

.card div {
	-ms-flex-item-align: center;
	align-self: center;
	margin: 0 auto;
	z-index: 11;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-transform: translateZ(16px);
	transform: translateZ(16px);
}

.card h3 {
	font-size: 2rem;
	line-height: 3rem;
}

.card hr {
	background: #fff;
	height: 2px;
	width: 24px;
	margin: 4px auto 8px;
}

.card h4 {
	font-size: 1.375rem;
	line-height: 2.0625rem;
	font-family: MD, -apple-system, \.SFNSText-Regular, San Francisco, Roboto, Segoe UI,
		Helvetica Neue, Lucida Grande, sans-serif;
	font-weight: 400;
	max-width: 512px;
}

.prevnext {
	margin-top: 200px;
}

.prevnext .card h4 {
	opacity: 0.8;
}

.prevnext .card:first-child {
	flex-grow: 0;
	flex-shrink: 0;
	flex-basis: calc(99.9% * 5/12 - 17.5px);
	width: calc(99.9% * 5/12 - 17.5px);
}

.prevnext .card:first-child:nth-child(1n) {
	margin-right: 30px;
	margin-left: 0;
}

.prevnext .card:first-child:last-child {
	margin-right: 0;
}

.prevnext .card:first-child:nth-child(12n) {
	margin-right: 0;
	margin-left: auto;
}

@media (max-width: 749px) {
	.prevnext .card:first-child {
		height: 170px;
		flex-grow: 0;
		flex-shrink: 0;
		flex-basis: calc(99.9% * 1/1 - 0px);
		width: calc(99.9% * 1/1 - 0px);
	}
	.prevnext .card:first-child:nth-child(1n) {
		margin-right: 30px;
		margin-left: 0;
	}
	.prevnext .card:first-child:last-child {
		margin-right: 0;
	}
	.prevnext .card:first-child:nth-child(1n) {
		margin-right: 0;
		margin-left: auto;
	}
}

.prevnext .card:nth-child(2) {
	flex-grow: 0;
	flex-shrink: 0;
	flex-basis: calc(99.9% * 7/12 - 12.5px);
	width: calc(99.9% * 7/12 - 12.5px);
}

.prevnext .card:nth-child(2):nth-child(1n) {
	margin-right: 30px;
	margin-left: 0;
}

.prevnext .card:nth-child(2):last-child {
	margin-right: 0;
}

.prevnext .card:nth-child(2):nth-child(12n) {
	margin-right: 0;
	margin-left: auto;
}

@media (max-width: 749px) {
	.prevnext .card:nth-child(2) {
		height: 170px;
		flex-grow: 0;
		flex-shrink: 0;
		flex-basis: calc(99.9% * 1/1 - 0px);
		width: calc(99.9% * 1/1 - 0px);
	}
	.prevnext .card:nth-child(2):nth-child(1n) {
		margin-right: 30px;
		margin-left: 0;
	}
	.prevnext .card:nth-child(2):last-child {
		margin-right: 0;
	}
	.prevnext .card:nth-child(2):nth-child(1n) {
		margin-right: 0;
		margin-left: auto;
	}
}

@media (max-width: 749px) {
	.card {
		flex-grow: 0;
		flex-shrink: 0;
		flex-basis: calc(99.9% * 1/1 - 0px);
		width: calc(99.9% * 1/1 - 0px);
	}
	.card:nth-child(1n) {
		margin-right: 30px;
		margin-left: 0;
	}
	.card:last-child {
		margin-right: 0;
	}
	.card:nth-child(1n) {
		margin-right: 0;
		margin-left: auto;
	}
	.card a {
		padding: 20px;
	}
}
